<template>
  <div>
      <Navbar/>
        <v-btn
        class="ma-2"
        outlined
        color="success"
        @click="showGroupForm"
        >
            Agregar grupo
        </v-btn>
      <TableGroups/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Navbar from '../components/Navbar'
import TableGroups from '../components/TableGroups'

export default {
    components: {
        Navbar,
        TableGroups
    },
    methods: {
        ...mapMutations("Groups", ["showGroupForm"])
    }
}
</script>
