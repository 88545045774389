<template>
  <v-dialog :value="FormValues.show" :persistent="true" max-width="1200px" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Agregar Grupo</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields Type="text" :Fn="setGroupName" :Value="FormValues.group_name" Label="Nombre del grupo *"/>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields :Fn="setContactName" Type="text" :Value="FormValues.contact_name" Label="Nombre del contacto *"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields :Fn="setContactPhone" Type="text" :Value="FormValues.contact_phone" Label="Teléfono del contacto"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-checkbox
                                v-model="showUserCheckbox"
                                value="1"
                                :label="`${ getCheckboxLabel(showUserCheckbox) }`"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        >
                            <v-slider
                            class="mt-3"
                            max="10"
                            min="1"
                            
                            :value="FormValues.priority"
                            @change="setPriority"
                            label="Prioridad"
                            thumb-label="always"
                            ></v-slider>
                        </v-col>
                    </v-row>
                </v-container>
                campos obligatorios: *
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeGroupForm()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="addGroup()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import TextFields from '../../StandardizedComponents/textFields'

export default {
    components: {
        TextFields
    },
    computed: {
        ...mapState("Groups", ["FormValues"]),
        showUserCheckbox:{
            get(){
                return this.FormValues.show_user
            },
            set(val){
                this.setShowUser(val)
            }
        }
    },
    methods: {
        getCheckboxLabel(){
            if(this.showUserCheckbox){return 'Mostrar usuario: Sí'} else{ return 'Mostrar usuario: No'} 
        },
        ...mapActions("Groups", ["addGroup"]),
        ...mapMutations("Groups", ["closeGroupForm", "showGroupForm", "setGroupName", "setContactName", "setContactPhone", "setShowUser","setPriority"])
    }
}
</script>

<style>

</style>