<template>
  <v-dialog :value="updateDialog" :persistent="true" max-width="1200px" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Modificar Grupo</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields Type="text" :Fn="setSelectedGroupName" :Value="selected.group_name" Label="Nombre del grupo *"/>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields :Fn="setSelectedContactName" Type="text" :Value="selected.contact_name" Label="Nombre del contacto *"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextFields :Fn="setSelectedContactPhone" Type="text" :Value="selected.contact_phone" Label="Teléfono del contacto"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-checkbox
                                v-model="showUserCheckbox"
                                value="1"
                                :label="`${ getCheckboxLabel(showUserCheckbox) }`"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12">
                            <MultiAutocomplete Label="Usuarios" :Fn="setUsersByGroup" :Value="ListOfUsersByGroup" :Items="dropdownListOfUsers" :Chips="true"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        >
                            <v-slider
                            class="mt-3"
                            max="10"
                            min="1"
                            
                            :value="selected.group_priority"
                            @change="setSelectedPriority"
                            label="Prioridad"
                            thumb-label="always"
                            ></v-slider>
                        </v-col>
                    </v-row>
                </v-container>
                campos obligatorios: *
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeUpdateGroupForm()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="updateGroup()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import TextFields from '../../StandardizedComponents/textFields'
import MultiAutocomplete from '../../StandardizedComponents/autocompleteMultiselect.vue'

export default {
    created(){
        this.$store.dispatch('Users/getUsers');
    },
    components: {
        TextFields,
        MultiAutocomplete
    },
    computed: {
        ...mapState("Groups", ["selected", "updateDialog", "groupUsers"]),
        ...mapState("Users", ["dropdownListOfUsers",'ListOfUsersByGroup']),
        showUserCheckbox:{
            get(){
                return this.selected.show_user
            },
            set(val){
                this.setSelectedShowUser(val)
            }
        }
    },
    methods: {
        getCheckboxLabel(){
            if(this.showUserCheckbox){return 'Mostrar usuario: Sí'} else{ return 'Mostrar usuario: No'} 
        },
        ...mapActions("Groups", ["updateGroup"]),
        ...mapMutations("Groups", ["closeUpdateGroupForm", "showUpdateGroupForm", "setSelectedGroupName", "setSelectedContactName", "setSelectedContactPhone", "setSelectedShowUser", "setSelectedPriority"]),
        ...mapMutations("Users", ["setUsersByGroup"])
    }
}
</script>

<style>

</style>