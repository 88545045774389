<template>
  <v-card>
    <v-card-title class="primary white--text headline">
      Lista de grupos
    </v-card-title>
    <v-row justify="space-between">
      <v-col cols="3">
        <v-text-field
          class="mx-2 mt-2"
          clearable
          outlined
          dense
          append-icon="mdi-magnify"
          @input="filterList($event)"
          :value="searchBar"
          label="Buscar..."
        ></v-text-field>
        <v-virtual-scroll :items="ListOfGroups" :item-height="120" height="700">
          <template v-slot:default="{ item }">
            <div>
              <v-list-item
                two-line
                class="my-5"
                active-class="white--text"
                :selectable="true"
                @click="setSelectedGroup(item)"
              >
                <v-list-item-avatar>
                  <v-avatar color="primary" size="31">
                    <span class="white--text headline">{{
                      item.group_name.substr(0, 1).toUpperCase()
                    }}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >Nombre: {{ item.group_name }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="text--primary"
                    >ID: {{ item.group_id }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    Prioridad: {{ item.group_priority }}
                  </v-list-item-action-text>
                  <v-icon :color="checkStatus(item.show_user)" small>
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-virtual-scroll>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col justify="center" align="center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            Select group
          </div>
          <v-card v-else :key="selected.group_id" class="pt-6 mx-auto" flat>
            <v-card-text>
              <v-avatar color="primary" size="93">
                <span class="white--text text-h2">{{
                  selected.group_name.substr(0, 1).toUpperCase()
                }}</span>
              </v-avatar>

              <h3 class="headline mb-2">
                {{ selected.group_name }}
              </h3>
              <div class="blue--text mb-2">
                ID: {{ isVoid(selected.group_id) }}
              </div>
              <div class="blue--text mb-2">
                Nombre del contacto: {{ isVoid(selected.contact_name) }}
              </div>
              <div class="blue--text mb-2">
                Teléfono del contacto: {{ isVoid(selected.contact_name) }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <p class="mt-5"></p>
            <v-card min-width="220" max-width="220" max-height="26" flat>
              <v-progress-linear
                :value="selected.group_priority * 10"
                :color="getProgressBarColor(selected.group_priority)"
                height="25"
              >
                <template>
                  <strong>Prioridad: {{ selected.group_priority }}</strong>
                </template>
              </v-progress-linear>
            </v-card>
            <div class="blue--text mb-2">Usuarios del grupo:</div>
            <v-row justify="center" align="center">
              <v-col justify="center" align="center">
                <v-chip-group>
                  <v-chip
                    class="mx-auto"
                    v-for="user in ListOfUsersByGroup"
                    :key="user.value"
                  >
                    {{ user.text }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <div class="blue--text mb-2">
              Grupo compartido: {{ isIt(selected.isShareGroup) }}
            </div>
            <div class="blue--text mb-2">
              Cuenta maestra: {{ isVoid(selected.master_account) }}
            </div>
            <div class="blue--text mb-2">
              Mostrar usuario: {{ isIt(selected.show_user) }}
            </div>
          </v-card>
        </v-scroll-y-transition>
        <FAB
          v-if="selected"
          :buttons="[
            {
              color: 'red',
              fn: showDeleteGroupForm,
              icon: 'mdi-delete',
              name: 'Eliminar grupo',
            },
            {
              fn: showUpdateGroupForm,
              icon: 'mdi-pencil-outline',
              color: 'green',
              name: 'Editar grupo',
            },
          ]"
        />
        <AddGroups />
        <UpdateGroup />
        <DeleteGroup />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FAB from "./StandardizedComponents/FAB";
import AddGroups from "./Dialogs/Groups/addGroups";
import UpdateGroup from "./Dialogs/Groups/modifyGroup";
import DeleteGroup from "./Dialogs/Groups/deleteGroups";

export default {
  components: {
    AddGroups,
    FAB,
    UpdateGroup,
    DeleteGroup,
  },
  created() {
    this.$store.dispatch("Groups/getTableGroups");
  },
  computed: {
    ...mapState("Groups", [
      "ListOfGroups",
      "selected",
      "searchBar"
    ]),
    ...mapState("Users", ["ListOfUsersByGroup"]),
  },
  methods: {
    ...mapMutations("Groups", ["showUpdateGroupForm", "showDeleteGroupForm"]),
    checkStatus(value) {
      if (value == "1") return "green";
      else return "red";
    },
    isVoid(value) {
      if (value === "" || value === undefined) {
        return "Sin datos para mostrar";
      } else {
        return value;
      }
    },
    isIt(value) {
      if (value === 0) {
        return "No";
      } else {
        return "Sí";
      }
    },
    getProgressBarColor(value) {
      if (value <= 3) {
        return "red";
      } else if (value > 3 && value <= 7) {
        return "yellow darken-1";
      } else if (value > 7) {
        return "green";
      }
    },
    ...mapActions("Groups", [
      "setSelectedGroup",
      "filterList"
    ]),
    ...mapActions("Users", ["getUserByGroup"]),
  },
};
</script>

<style>
</style>