<template>
  <v-dialog :value="deleteDialog" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Eliminar grupo
      </v-card-title>

      <v-card-text class="pt-2">
        Usted esta a punto de eliminar el grupo con el id {{ selected.group_id }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDeleteGroupForm">Cerrar</v-btn>
        <DeleteProgressButton :Fn="deleteGroup"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteProgressButton from '../../StandardizedComponents/deleteProgressButton'
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState("Groups", ["deleteDialog", "selected"]),
    },
    components:{
        DeleteProgressButton
    },
    methods:{
      ...mapActions("Groups",['deleteGroup']),
      ...mapMutations("Groups",['closeDeleteGroupForm'])
    }
    
};
</script>

<style>
</style>